import React from 'react';
import Hero from "./Hero";
import Description from "./Description";
import { Spring } from "react-spring/renderprops";


function HeroSectionContainer() {
    return (
        <section id="home">
        <div  className="relative bg-white overflow-hidden ">
            <div  className="max-w-screen-xl mx-auto ">
         
                <div  className=" mt-20 flex flex-col md:flex-row relative pb-8 bg-white "> 
                    
                    <Hero/>
                    <div  className="w-full  relative">
                            <Spring from={{opacity:0, marginTop:-500}} to={{opacity:1,marginTop:0}} config={{delay:1000, duration:1000}}>
                {(props) => (
                    
                              <div  className=" hidden md:flex absolute h-full w-full  items-center justify-center  left-0 bottom-0 px-3 ">
                    <img className= "h-full w-full object-center    "
                                src="/img/coding.svg"
                                alt="freepik coding svg"  styles="animation: scale 4s linear infinite "  
                            />
                        </div>
                       


                  
                      

                        
                       
              
                )}
            </Spring>
            
            </div>
                    
                </div>
            </div>
    </div>
  <Description />
        </section>
    )
}

export default HeroSectionContainer
