import React from 'react';
import { Link } from 'react-scroll';
import { Spring } from 'react-spring/renderprops';


function Hero() {
    return (
        <div>
           <main  className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
                <div  className="sm:text-center lg:text-left">
                    <Spring from={{opacity:0}} to={{opacity:1}} config={{delay:1000, duration:1000}}>
                        {(props) => (
                            <div style={props}>
                                 <h2  className="text-2xl leading-tight md:leading-tight  font-extrabold text-gray-900 sm:text-5xl  md:text-4xl tracking-wide ">
                                   Ideas into perfectly engineered digital products.
                                     <br  className="xl:hidden"/>  
                                 </h2> 
                            </div>
                        )}
                    </Spring>
                    <p  className="mt-3 text-base text-gray-700 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                        Our dedicated developers at Tricode technology are skilled and capable enough to  engineer your ideas and concepts into real products.
                     </p>
                    <div  className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                        <div  className="rounded-md shadow">
                            <Link to="contact" smooth={true}  className=" cursor-pointer w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-indigo-600 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">contact us</Link>
                        </div>
                        <div  className="mt-3 sm:mt-0 sm:ml-3">
                            <Link to="services" smooth={true}  className="cursor-pointer w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-red-600 bg-red-100 hover:text-red-600 hover:bg-red-50 focus:outline-none focus:border-indigo-300 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">Browse</Link>
                        </div>
                        
                      
                       
                    </div>
                    <div class="md:hidden">
                        <img className="w-full h-auto" src="/img/dev.svg" alt="dev svg from freepik"/>
                    </div>
                </div>
           </main>
        </div>
    )
}

export default Hero
