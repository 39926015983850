import React from 'react';
import { Link } from 'react-scroll';



function Footer() {
   
    return (
        <div  className="py-12 bg-gray-900 h-72">
            <div  className="max-w-screen-xl mx-auto px-4 sm-px sm:px-6 lg:px-8">
         


         	
		<div  className="lg:flex">
            <div  className="flex-1">
                <p  className="text-gray-100 text-lg font-semibold md:mb-6">Links</p>
              
              
                <ul  className="list-reset mb-6 text-gray-500 font-semibold flex flex-row lg:flex-col gap-2">
								<li   className=" block cursor-pointer mt-1 block px-3 py-2 rounded-md text-base font-medium  hover:text-orange-500 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" role="menuitem"> <Link to="home"  smooth={true} >Home</Link></li>
                      <li   className="cursor-pointer mt-1 block px-3 py-2 rounded-md text-base font-medium  hover:text-orange-500 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" role="menuitem">  <Link to="services" smooth={true}>Services</Link></li>
                     <li  className="cursor-pointer mt-1 block px-3 py-2 rounded-md text-base font-medium  hover:text-orange-500 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" role="menuitem">   <Link to=""  smooth={true}>Blogs</Link></li>
                        <li   className=" cursor-pointer mt-1 block px-3 py-2 rounded-md text-base font-medium  hover:text-orange-500 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" role="menuitem"><Link to="about" smooth={true}>About us</Link></li>
                       <li  className=" cursor-pointer mt-1 block px-3 py-2 rounded-md text-base font-medium  hover:text-orange-500 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" role="menuitem"> <Link to="contact"  smooth={true}>Contact us</Link></li>
                   
                </ul>


            </div>


               <div  className="flex-1">
							<p  className=" text-gray-100 text-lg font-semibold md:mb-6">Services</p>
							<ul  className="flex flex-row flex-wrap lg:flex-col list-reset mb-6 text-gray-500">
							<li   className=" block cursor-pointer mt-1 block px-3 py-2 rounded-md text-base font-medium  hover:text-orange-500 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" role="menuitem"> <Link to="services"  smooth={true} >Web development</Link></li>
                      <li   className="cursor-pointer mt-1 block px-3 py-2 rounded-md text-base font-medium  hover:text-orange-500 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" role="menuitem">  <Link to="services" smooth={true}>Mobile application development</Link></li>
                     <li  className="cursor-pointer mt-1 block px-3 py-2 rounded-md text-base font-medium  hover:text-orange-500 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" role="menuitem">   <Link to="services"  smooth={true}>SEO and Digital marketing</Link></li>
                   
							</ul>
					</div>
           

					<div  className="flex-1">
						<p  className=" text-gray-100 text-lg font-semibold md:mb-6">Have a Questions?</p>
						<div  className="flex justify-start items-center  mt-2"> <svg  className="h-4 w-4 fill-current text-gray-500 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10 20S3 10.87 3 7a7 7 0 1 1 14 0c0 3.87-7 13-7 13zm0-11a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/></svg><span  className="text-sm md:text-md text-gray-500">Sanepa-2, Lalitpur</span></div>
<div  className="flex justify-start items-center mt-2"> <svg  className="h-4 w-4 fill-current text-gray-500 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M20 18.35V19a1 1 0 0 1-1 1h-2A17 17 0 0 1 0 3V1a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4c0 .56-.31 1.31-.7 1.7L3.16 8.84c1.52 3.6 4.4 6.48 8 8l2.12-2.12c.4-.4 1.15-.71 1.7-.71H19a1 1 0 0 1 .99 1v3.35z"/></svg><span  className="text-sm md:text-md text-gray-500">+977-9847716201</span></div>
           
              <div  className="flex justify-start items-center   mt-2"><svg  className="h-4 w-4 fill-current text-gray-500 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M18 2a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4c0-1.1.9-2 2-2h16zm-4.37 9.1L20 16v-2l-5.12-3.9L20 6V4l-10 8L0 4v2l5.12 4.1L0 14v2l6.37-4.9L10 14l3.63-2.9z"/></svg> <span  className="text-sm md:text-md text-gray-500">tricodeofficial@gmail.com</span></div>
            
				</div>
                </div>
            
        </div>




            </div>
          
       
    )
}

export default Footer
