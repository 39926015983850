import React from 'react'

function Description() {
    return (
      
        <div  className="max-w-screen-xl my-20  mx-auto px-4 sm-px sm:px-6 lg:px-8">
       
        <div  className=" rounded-xl bg-gradient-to-r from-red-500 py-8 via-orange-500 to-yellow-500 flex  flex  flex-wrap w-full  flex-col items-center text-center">
           <h1  className="sm:text-3xl text-2xl font-semibold title-font mb-2 text-gray-900">
               "Bring <span  className="text-orange-200">Happiness</span> to your  Digital Products"
           </h1>
           <p  className="lg:w-1/2 w-full leading-relaxed text-base">
        
           </p>
        </div>
        </div>
    )
}

export default Description
