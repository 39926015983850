import React from 'react'

function FeatureItems({title,description}) {
    return (
      <li  className="my-4">
        
        <div  className="header-content inline-flex my-1 md:my-2">
                    <div  className="category-badge flex-1 h-4 w-4 m rounded-full m-1 bg-red-100">
                        <div  className="h-2 w-2 rounded-full m-1 bg-red-500"></div>
                    </div>
                    <div  className="title-post font-medium flex:1 text-lg  font-semibold">{title}</div>
                </div>
                <div  className="summary-post text-base text-justify text-gray-700">
                    {description}
                </div>
               
       
      </li>
    )
}

export default FeatureItems
