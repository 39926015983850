import React from 'react';
import { Link } from 'react-scroll';
import MobileNav from './MobileNav';


function NavBar() {
    return (
       <>
         <div  className=" w-full fixed  bg-white  z-10 ">
            <div  className="  max-w-screen-xl mx-auto ">
         
            <svg viewBox="0 0 100 100" fill="currentColor"  className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2" preserveAspectRatio="none">
                 <polygon points="50,0 100,0 50,100 0,100"/>
            </svg>
            <div  className="relative  px-4 sm:px-6 lg:px-8">
                <nav  className="relative flex items-center justify-between   ">
                    <div  className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                        <div  className="flex items-center justify-between w-full md:w-auto ">
                                   <img   className=" mx-auto h-18 w-18 xl:h-18 xl:w-12 py-3"src="img/round.svg" alt="tricode Technology" />

          <a href="/"  className="inline-block  ml-2 py-2 text-textPrimary lg:text-xl font-Poppins font-semibold"
            > Tricode Technology</a>
                            <div  className="-mr-2 flex items-center md:hidden">
                                <button type="button" id="main-menu" aria-label="Main menu" aria-haspopup="true"  className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
                                    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 13H17M1 1H17H1ZM1 7H17H1Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    
                   
                    <ul id="nav" className=" flex hiddden md:block md:ml-10 md:pr-4">
                       <li   className=" nav-item inline-block cursor-pointer font-medium px-4 text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out"> <Link to="home" className="true" active="true"  spy={true} smooth={true} >Home</Link></li>
                      <li   className=" nav-item inline-block cursor-pointer px-4 font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out">  <Link to="services" active="true"  spy={true} smooth={true}>Services</Link></li>
                     
                        <li   className="nav-item inline-block cursor-pointer px-4 font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out"><Link to="about" active="true"  spy={true} smooth={true}>About us</Link></li>
                       <li   className="nav-item inline-block cursor-pointer px-4 font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out"> <Link to="contact" active="true"  spy={true} smooth={true}>Contact us</Link></li>
                        
                    </ul>
                </nav>
            </div>

         <MobileNav/>
         </div>
         </div>
       </>
    )
}

export default NavBar
