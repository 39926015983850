import React from 'react'

function Heading({title}) {
    return (
        <div  className="pb-2 ">
          
          <h1  className="sm:text-4xl text-2xl font-semibold title-font mb-2 text-gray-800 flex items-center gap-4">
           <svg  className="h-8 w-8 md:h-12 md:w-12 " viewBox="0 0 325 366" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M50.383 279.675H26.733C11.825 279.675 0 267.851 0 252.942C0 238.547 11.825 226.209 26.733 226.209H59.637L75.061 136.754H45.242C30.847 136.754 18.509 124.416 18.509 110.021C18.509 95.626 30.847 83.288 45.242 83.288H84.315L94.597 22.108C96.654 8.741 107.964 0 121.33 0C138.296 0 150.12 15.424 148.063 31.361L138.809 83.287H219.525L229.808 22.108C231.865 8.741 243.175 0 257.056 0C273.508 0 285.846 15.424 283.276 31.361L274.537 83.287H298.186C312.581 83.287 324.919 95.111 324.919 110.02C324.919 124.415 312.581 136.753 298.186 136.753H265.282L249.859 226.208H279.162C294.07 226.208 305.895 238.033 305.895 252.941C305.895 267.336 294.07 279.674 279.162 279.674H240.604L229.808 342.91C227.751 355.763 216.441 365.018 203.075 365.018C186.11 365.018 173.77 349.594 176.342 333.657L186.11 279.675H105.392L94.595 342.911C92.538 354.221 82.771 365.019 67.347 365.019C50.895 365.019 38.557 351.137 41.127 333.658L50.383 279.675ZM194.847 226.207L210.27 136.752H130.069L114.646 226.207H194.847Z" fill="#FF3E47"/>
</svg><span  className="block">{title}</span>
           </h1>
        </div>
    )
}

export default Heading