import React from 'react'

import ServiceCard from './servicesCard';
import {services} from '../context'
import Heading from './Heading';

import Feature from './Feature';




function CardsContainer() {
    
        
    return (
        <section id="services">
        <div  className=" text-gray-700 body-font">
           
        <div  className="max-w-screen-xl mx-auto px-4 sm-px sm:px-6 lg:px-8">
               <Heading title="Services" />
                <div  className="flex flex-wrap  -mb-10 -mt-4">
                  
               {services.map((item)=>{

return (<ServiceCard
key={item.id}
title={item.title}
icon={item.icon}
  description={item.description}
  
/>);

})}
                   
                </div>
            </div>
            </div>
            <Feature />
            
        </section>
    )
}

export default CardsContainer
