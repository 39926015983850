import React from 'react';
import {about} from '../context';
import GetQuote from './GetQuote';
import Heading from './Heading';

function About() {
    
    return (
        <section id="about"
        ><GetQuote />
            
            
            <div  className="py-12 bg-white">
            <div  className="max-w-screen-xl mx-auto px-4 sm-px sm:px-6 lg:px-8">
           <Heading title="About us" />
                <div  className="md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
                   <img   className="object-center  w-full mb-2 px-4 max-w-sm"src={about.image} alt="contact" />
                   <p  className=" h-full flex items-center justify-center text-base text-justify text-gray-800 md:text-lg">&nbsp; &nbsp; &nbsp; &nbsp; {about.content}</p>
                </div>
            </div>
            </div>
        </section>
    )
}

export default About
