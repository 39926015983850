import HeroSectionContainer from "./components/HeroSectionContainer";

import CardsContainer from "./components/CardsContainer";
import Contact from "./components/Contact";

import About from "./components/About";
import Footer from "./components/Footer";

import NavBar from "./components/NavBar";
import {BrowserRouter as Router } from 'react-router-dom';

function App() {
  return (

    <Router>
         <NavBar />
        
               <HeroSectionContainer/>
                 
                  
                    <CardsContainer/>
                  
                    <About />
                    <Contact />
                   <Footer />
                    
               
    </Router>
  );
}

export default App;
