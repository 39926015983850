import React from 'react'

function ServiceCard({icon, title, description}) {
    return (
        <>
        
        <div 
                 className="w-full bg-white flex-auto flex flex-col items-center justify-center md:block  md:w-1/2 flex-1  rounded-lg overflow-hidden py-10 md:p-5 "
           >
               <div  className="bg-red-200 h-24 w-24 mb-4  flex object-cover justify-center items-center p-4 w-18 rounded-lg "
           >
               <img   className="object-cover w-full h-full rounded-md" src={icon} alt={title}/>
           </div>


           <div  className="header-content inline-flex my-2">
                    <div  className="category-badge flex-1 h-4 w-4 m rounded-full m-1 bg-red-100">
                        <div  className="h-2 w-2 rounded-full m-1 bg-red-500"></div>
                    </div>
                    <h4  className="title-post font-medium flex:1 text-lg text-gray-900  font-semibold">{title}</h4>
                </div>
                <div  className="summary-post text-base text-justify text-gray-700">
                    {description}
                </div>
           </div>
      
        </>
       
    )
}

export default ServiceCard
