import React from 'react';
export default  function ResponseMessage( {success,message}){
    return success===true?(<div className={"appearance-none border-dashed block text-center w-full  mt-2 text-gray-700 border border-green-800 rounded-xl py-3 px-4 mb-1 leading-tight focus:outline-none resize-none focus:bg-white focus:border-gray-500 bg-green-100" } >
    {message}
</div>): (
  
      <div className={"appearance-none border-dashed block text-center w-full  mt-2 text-gray-700 border border-red-800 rounded-xl py-3 px-4 mb-1 leading-tight focus:outline-none resize-none focus:bg-white focus:border-gray-500 bg-red-100" } >
                 {message}
      </div>
    )
  }