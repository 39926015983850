import React,{useState} from 'react'
import { Link } from 'react-scroll'


function MobileNav() {
    const [isMenuShown, setisMenuShown] = useState(false)

    function toogleMobileNav()
{
 
    setisMenuShown(isMenuShown?false:true);
 
}

    return (
        <div  className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-90  ">
           <div  className="rounded-lg shadow-md">
                <div  className="rounded-lg bg-white shadow-xs overflow-hidden" role="menu" aria-orientation="vertical" aria-label="main-menu">
                    <div  className="px-5  flex items-center justify-between">
                        <div  className="flex items-center">
                        <img   className=" mx-auto h-18 w-18 xl:h-18 xl:w-12 py-3"src="img/round.svg" alt="tricode Technology" />

<a href="/"  className="inline-block  ml-2 py-2 text-textPrimary lg:text-xl font-Poppins font-semibold"
  > Tricode Technology</a>
                       </div>
                       <div  className="-mr-2">
                        <button type="button"  className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover-text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out" aria-label="Close menu" onClick={toogleMobileNav}>
                           
                            <svg className="text-gray-900 h-6"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h7" />
</svg>
                        </button>
                    </div>
            </div>
            <div className="transition transition-all duration-500 ">
            <div  className={isMenuShown?" px-2 pt-2 pb-3":"hidden"}>
           
           <li   className="cursor-pointer mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" role="menuitem"> <Link to="home"  smooth={true} >Home</Link></li>
                     <li   className="cursor-pointer mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" role="menuitem">  <Link to="services" smooth={true}>Services</Link></li>
                   
                       <li   className="cursor-pointer mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" role="menuitem"><Link to="about" smooth={true}>About us</Link></li>
                      <li  className="cursor-pointer mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" role="menuitem"> <Link to="contact"  smooth={true}>Contact us</Link></li>
                  
           </div>
           </div>
       
            </div>
        </div>  
      </div> 
    )
}

export default MobileNav
