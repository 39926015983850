import React from 'react';
import {features} from '../context';
import Heading from "./Heading";
import FeatureItems from "./FeatureItems";


function Feature() {
   
    return (
        <div  className="py-12 bg-white">
            <div  className="max-w-screen-xl mx-auto px-4 sm-px sm:px-6 lg:px-8 mt-12">
            <Heading title="Why TriCode Technology?" />
                <div  className="">
                    <ul  className="md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
                       {features.map((item)=>{
                           
                           return <FeatureItems key={item.id} title={item.title} description={item.description} />
                       })}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Feature
