import React from 'react';

function GetQuote() {
    return (
        <div  className="max-w-screen-xl my-20  mx-auto px-4 sm-px sm:px-6 lg:px-8">
       
        <div  className=" rounded-xl bg-gradient-to-r from-red-500 py-8 via-orange-500 to-yellow-500 flex  flex-wrap w-full  flex-col items-center text-center">
           <h1  className="sm:text-3xl text-2xl font-semibold title-font mb-2 text-gray-900">
           "We are <span  className="text-orange-200">Dedicated</span> to Our Client’s <span  className="text-orange-200">Success</span>" 
           </h1>
           <h1  className="sm:text-3xl text-2xl font-semibold title-font mb-2 text-gray-900">
           
    "We understand <span  className="text-orange-200">business</span>"
           </h1>
           <button  className=" transition duration-300 mt-4 px-8 py-3 border border-red-300 bg-red-600 font-semibold leading-6 font-medium rounded-md text-white  hover:text-white hover:bg-red-500 hover:text-white focus:outline-none focus:border-red-300 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10" type="submit" id="submitBtn" value="submit">  Get Quote </button>
   
        </div>
        </div>
    )
}

export default GetQuote
