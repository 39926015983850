import React,{useState} from 'react'

import Heading from './Heading'
import ResponseMessage from './ResponseMessage';




function useInput(initialValue){
  const [value,setValue] = useState(initialValue);
   function handleChange(event){

     if(event.target)setValue(event.target.value);
    else setValue(event);    }
  return [value,handleChange];
}




 function Contact() {

 const [name, setName] = useInput('');
 const [email,setEmail] = useInput('');
 const [message,setMessage] = useInput('');
 const [disable,setDisable]=useState(false)
 const [response,setResponse]=useState(false);
 const [isSending,setIsSending]=useState(false);


 async function onFormSubmit(e)
{
 
   setResponse(false);
  setIsSending(true);
  e.preventDefault();
  let data={username:name,email:email,shortMessage:message};
  let response = await fetch('https://twitter-starter.herokuapp.com/api/v1/tricode/message', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify(data)
  });
  
 
  setDisable(true);
 await response.json().then((data)=>{
setResponse({isSuccess:data.isSuccess,message:data.response})
setDisable(false);
setName("")
setEmail("")
setMessage("")
setIsSending(false);
  });

  
}
 

    return (
        
        <section id="contact"  className="py-12 bg-white">
            <div  className="max-w-screen-xl mx-auto px-4 sm-px sm:px-6 lg:px-8">
            <Heading title="Contact us" />
            <div  className="md:flex w-full  ">
                <div  className="mt-12 md:w-1/2">
                <form  id="message-form" onSubmit={onFormSubmit}>

<div >
  <label  className="block  tracking-wide text-gray-600 text-sm font-medium mt-8" htmlFor="inputName">
    Name
  </label>
  <input disabled={disable}  className="appearance-none block w-full bg-gray-200 mt-2 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="inputName" required value={name} onChange={setName} type="text" placeholder="Name" />
 
</div>
  
<div  className="mt-4">
<label  className="text-sm font-medium text-gray-600" htmlFor="inputEmail">Email</label>
<input disabled={disable}  className="appearance-none block w-full bg-gray-200 mt-2 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="inputEmail" required value={email} onChange={setEmail} type="email" placeholder="Email" />
   
</div>
<div  className="mt-4">
    <label  className="text-sm font-medium text-gray-600" htmlFor="inputMessage">short message</label>
    <textarea  disabled={disable}  className="appearance-none block w-full bg-gray-200 mt-2 text-gray-700 border border-gray-200 rounded-xl py-3 px-4 mb-1 leading-tight focus:outline-none resize-none focus:bg-white focus:border-gray-500" id="inputMessage" type="text"   rows="6" required placeholder="message" value={message} onChange={setMessage}></textarea>
    <div  className="message-box">
                {response!==false?<ResponseMessage success={response.isSuccess} message={response.message} />:""}
                </div>
    <button disabled={disable}  className=" transition duration-300 mt-4 px-8 py-3 border border-red-500 text-base leading-6 font-medium rounded-md text-red-600 bg-red-100 hover:text-white hover:bg-red-500 hover:text-white focus:outline-none focus:border-red-300 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10" type="submit" id="submitBtn" value="submit">  submit{isSending?"ing ":" "} message{isSending?".... ":" "} </button>
   
              
                 </div>	
                </form>
                </div>
                <div  className=" mt-8 w-full md:w-1/2 md:flex md:flex-col md:justify-center md:items-center">

 <img  className="hidden md:block object-cover mx-auto" styles="max-height: 25rem;" src="img/contact.svg" alt="contact" />
 <div  className=" sm:ml-4">
 <div  className="flex justify-start items-center  mt-2"> <svg  className="h-4 w-4 fill-current text-gray-600 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10 20S3 10.87 3 7a7 7 0 1 1 14 0c0 3.87-7 13-7 13zm0-11a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/></svg><span  className="text-sm md:text-md text-gray-600">Sanepa, Lalitpur</span></div>
 <div  className="flex justify-start items-center mt-2"> <svg  className="h-4 w-4 fill-current text-gray-600 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M20 18.35V19a1 1 0 0 1-1 1h-2A17 17 0 0 1 0 3V1a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4c0 .56-.31 1.31-.7 1.7L3.16 8.84c1.52 3.6 4.4 6.48 8 8l2.12-2.12c.4-.4 1.15-.71 1.7-.71H19a1 1 0 0 1 .99 1v3.35z"/></svg><span  className="text-sm md:text-md text-gray-600">+977-9847716201</span></div>
           
               <div  className="flex justify-start items-center   mt-2"><svg  className="h-4 w-4 fill-current text-gray-600 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M18 2a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4c0-1.1.9-2 2-2h16zm-4.37 9.1L20 16v-2l-5.12-3.9L20 6V4l-10 8L0 4v2l5.12 4.1L0 14v2l6.37-4.9L10 14l3.63-2.9z"/></svg> <span  className="text-sm md:text-md text-gray-600">tricodeofficial@gmail.com</span></div>
                
               </div>
            </div>
            </div> 
         </div>
            </section>
     
    )
}

export default Contact
