export const services=[
    {
        id:1,
        title:"Mobile application Development",
        icon:"img/android.svg",
        description:"We specialize in building mobile applications for Hybrid and Native iOS/Android platforms We are able to provide self-maintainable mobile apps with a secure website backend. We build native mobile apps in iOS & Android platforms along with Hybrid applications. "
    },
    {
        id:2,
        title:"web Services",
        icon:"img/we.svg",
        description:" Along with website development services for different sectors, we offer high-end consulting services, including expert guidance and project management related to website design and web app development. "
    },
    {
        id:3,
        title:"Digital marketing and SEO",
        icon:"img/marketingandseo.svg",
        description:" We not only build your products, but we also make sure that your products get the attention of your potential customers. Through digital marketing and search engine optimizations, we make sure your products get the attention of your potential customers"
    },
    {
        id:4,
        title:"Iot",
        icon:"img/iot.svg",
        description:" We not only build your products, but we also make sure that your products get the attention of your potential customers. Through digital marketing and search engine optimizations, we make sure your products get the attention of your potential customers"
    },
    
    
]

export const features=[
    {
        id:1,        
        title:"Free quotes & consultation",
        description:"We come for free quotes and there is no cost for the initial meetings where we grab your basic needs and expectations. We believe a job rushed is a job not worth doing so planning is a central priority for our business model"
    },{
        id:2,
        title:"We'll understand your brand",
        description:"Before We even start writing, We think about your tone of voice and value proposition, so every word builds your brand. "
    },
    {
        id:3,
        title:"We guarantee our work",
        description:"Jobs not complete until you are satisfied. We guarantee you'll be happy with our work. We Listen,Suggest & Update. We have a proven methodology."
    },
    {
        id:4,
        title:"Quality meed affordability",
        description:"It is our priority to help your business reduce expenses by providing Quality products at affordable costs. We guarantee your products are on time and prefectly fit the needs and behavior of your users."
    }
    ,{
        id:5,
        title:"Close Collaboration with Clients",
        description:"At Tricode, we value the relationship we have with our clients. We are always willing to listen to your suggestions and adapt to your specific needs. With regular meetings and updates, we make sure you are always a part of the team."
    },
    {
        id:6,
        title:"Dedicated Workforce",
        description:"TriCode Technology is conposed of talented Designers & Expert Developers. We work with a proven methodology and constantly focus on innovating and improving ourselves. "
    }
]
export const about={
    image:"img/about.svg",
    content:"TriCode Technology is a Digital Agency Born in Kathmandu that provides services related to Website designing, Website Redesigning, Responsive Website, Mobile application development ,digital marketing ans search engine optimization. We can transform your businesses by our strong technical ability with our deep understanding of the digital landscape.We have a results-oriented team, working with the right technology for the right job, ensuring total quality with precision and high-value returns. Our Research and Development continually explore new technologies to offer our customers the best, generating a high level of satisfaction in them. "
}